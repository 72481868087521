import React from 'react';
import PropTypes from 'prop-types';
// components
import SectionTrigger from './SectionTrigger';

const ContactEmail = ({ bgColor, textColor, children }) => (
  <SectionTrigger bgColor={bgColor} textColor={textColor} threshold={0.175}>
    <div className="contact contact--email">
      <a
        id="contactEmail"
        className="contact-title contact-title--email hide-desktop"
        href="mailto:hello@falkoncontent.com"
      >
        hello@ falkoncontent.com
      </a>
      <a
        id="contactEmail"
        className="contact-title contact-title--email desktop-only"
        href="mailto:hello@falkoncontent.com"
      >
        hello@falkoncontent.com
      </a>
      <footer className="fix contact-footer contact-footer--email">
        <div className="contact-footer__inner">{children}</div>
      </footer>
    </div>
  </SectionTrigger>
);

ContactEmail.propTypes = {
  bgColor: PropTypes.string,
  children: PropTypes.object,
  textColor: PropTypes.string,
};

export default ContactEmail;
