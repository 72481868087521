import React from 'react';
import { Tween, Timeline } from 'react-gsap';
import { Controller, Scene } from 'react-scrollmagic';
// components
import * as Styled from 'src/components/about/AboutStyled';
import SectionTrigger from 'src/components/SectionTrigger';
import AutoPlayVideo from 'src/components/AutoPlayVideo';
import AboutSkills from 'src/components/AboutSkills';
import ContactEmail from 'src/components/ContactEmail';
import ContactPhone from 'src/components/ContactPhone';
import ContactList from 'src/components/ContactList';

function AboutContent({ images }) {
  const [img1, img2, img3, img4, img5, img6] = images;
  const protoVideo =
    'https://player.vimeo.com/external/433464678.sd.mp4?s=18657b4b7e3545697b85299182b2934a2be5f7ac&profile_id=164&oauth2_token_id=1365998561';
  const wmnsVideo =
    'https://player.vimeo.com/external/433464686.sd.mp4?s=e1b4e90515d8767836f4f2c363c323e261337a08&profile_id=139&oauth2_token_id=1365998561';

  return (
    <div>
      <SectionTrigger
        className="about__section about__section--1"
        bgColor="#AFD1D7"
        textColor="#EFEDE7"
      >
        <div className="grid__container grid__container--about">
          <div className="grid">
            <Styled.Section1Title
              className="about__title about__title--1"
              y={[0, -30]}
            >
              <h2 className="main-heading">
                Born At The Intersection Of Advertising &amp; Entertainment
              </h2>
            </Styled.Section1Title>
            <Styled.Section1Video1 y={[-25, 5]}>
              <AutoPlayVideo
                poster={img1}
                src={wmnsVideo}
                style={{ width: '100%' }}
              />
            </Styled.Section1Video1>
            <Styled.Section1Blurb1 y={[75, -25]}>
              <p>
                We’re going beyond what’s NOW to boldly define what’s NEXT
                through work that focuses on Sports, Culture &amp; Social
                Impact.
              </p>
            </Styled.Section1Blurb1>
            <Styled.Section1Img1 y={[-40, 20]}>
              <img src={img2} alt="basketball hoop" />
            </Styled.Section1Img1>
            <Styled.Section1Blurb2 y={[0, -50]}>
              <p>
                We’ve taken the best of film development, production &amp;
                marketing and blended it with innovative advertising for a
                seamless process from insight to audience.
              </p>
            </Styled.Section1Blurb2>
          </div>
        </div>
      </SectionTrigger>
      <SectionTrigger
        className="about__section about__section--2"
        bgColor="#DFB8AF"
        textColor="#EFEDE7"
      >
        <div className="grid__container grid__container--about">
          <div className="grid">
            <Styled.Section2Quote1>
              <blockquote>
                <p>
                  Our brand stories are entertaining &amp; our entertainment
                  is highly marketable, regardless of form, function and
                  destination.
                </p>
              </blockquote>
            </Styled.Section2Quote1>
            <Styled.Section2Img1 y={[0, 10]}>
              <img src={img3} alt="girl sitting" />
            </Styled.Section2Img1>
            <Styled.Section2Blurb1 y={[40, 0]}>
              <p>
                Our unique pov fills a growing demand by brands, film studios
                and content platforms to forge new paths amid a converging
                marketplace with an ever-changing audience.
              </p>
            </Styled.Section2Blurb1>
            <Styled.Section2Video1 y={[-30, 30]}>
              <AutoPlayVideo
                poster={img4}
                src={protoVideo}
                style={{ width: '100%' }}
              />
            </Styled.Section2Video1>
            <Styled.Section2Img2 y={[30, 60]}>
              <img src={img5} alt="lace up shoes" />
            </Styled.Section2Img2>
            <Styled.Section2Blurb2 y={[60, 30]}>
              <p>
                Our special team of high-functioning “hyphenates” allow us to
                collapse the layers of advertising & entertainment and work
                more efficiently and successfully across both disciplines -
                applying the best of each world, integrating, innovating and
                radically discovering what’s next in Content.
              </p>
            </Styled.Section2Blurb2>
          </div>
        </div>
      </SectionTrigger>
      <SectionTrigger
        className="about__section about__section--3"
        bgColor="#DFB8AF"
        textColor="#EFEDE7"
      >
        <div className="about-zoom">
          <div className="about-zoom__section">
            <Controller>
              <Scene triggerHook="onLeave" duration="100%" pin>
                <Timeline wrapper={<div className="pin-container" />}>
                  <Tween from={{ css: { scale: 0.2 } }}>
                    <div>
                      <img
                        src={img6}
                        alt="photo shoot"
                        style={{
                          position: 'initial',
                          width: '100%',
                          objectPosition: 'bottom',
                        }}
                      />
                    </div>
                  </Tween>
                </Timeline>
              </Scene>
            </Controller>
            <div className="about-zoom__hdr">
              <h3 className="about-zoom__title">Skills & Capabilities</h3>
            </div>
          </div>
        </div>
      </SectionTrigger>
      <AboutSkills bgColor="#efede6" textColor="#2e63a0" />
      <div className="about__footer">
        <ContactEmail bgColor="#f08f7c" textColor="#000">
          <ContactList />
        </ContactEmail>
        <ContactPhone bgColor="#e1b9af" textColor="#fff">
          <ContactList />
        </ContactPhone>
      </div>
    </div>
  )
}

export default AboutContent;