import React from 'react';
import { Parallax } from 'react-scroll-parallax';
// components
import AutoPlayVideo from 'src/components/AutoPlayVideo';
import ParallaxImage from 'src/components/parallax/ParallaxImage';
import SectionTrigger from 'src/components/SectionTrigger';
import AboutSkillsMobile from './AboutSkillsMobile';

function AboutContentMobile({ images }) {
  const scrollOffset = [40, -80];
  const [img01, img02, img03, img04, img05, img06] = images;
  const protoVideo =
    'https://player.vimeo.com/external/433464678.sd.mp4?s=18657b4b7e3545697b85299182b2934a2be5f7ac&profile_id=164&oauth2_token_id=1365998561';
  const wmnsVideo =
    'https://player.vimeo.com/external/433464686.sd.mp4?s=e1b4e90515d8767836f4f2c363c323e261337a08&profile_id=139&oauth2_token_id=1365998561';

  return (
    <div className="about-mobile">
      <div className="about-mobile__container">
        <h1 className="about-mobile__heading">About</h1>
        <h3 className="about-mobile__subheading">
          Born at the intersection of advertising & entertainment
        </h3>
        <p className="about-mobile__copy about-mobile__copy--1">
          We’re going beyond what’s NOW to boldly define what’s NEXT through work that focuses on Sports, Culture & Social Impact.
        </p>
        <p className="about-mobile__copy about-mobile__copy--2">
          We’re going beyond what’s NOW to boldly define what’s NEXT through work that focuses on Sports, Culture & Social Impact.
        </p>
        <blockquote className="about-mobile__blockquote about-mobile__blockquote--1">
          Our brand stories are entertaining & our entertainment is highly marketable
        </blockquote>
        <p className="about-mobile__copy about-mobile__copy--3">
          We fill a growing demand by brands, film studios and content platforms to forge new paths amid a converging marketplace.
        </p>
        <p className="about-mobile__copy about-mobile__copy--4">
          Our team of high-functioning “hyphenates” collapses the layers & applies the best of each world — integrating, innovating and powerfully discovering new ways to reach an ever-changing audience.
        </p>
        <blockquote className="about-mobile__blockquote about-mobile__blockquote--2">
          Skills & capabilities
        </blockquote>
        <AboutSkillsMobile bgColor="#efede6" textColor="#2e63a0" />
        <a className="about-mobile__contact-title about-mobile__contact-title--1" href="mailto:hello@falkoncontent.com">
          hello@<br />falkoncontent<br />.com
        </a>
        <h3 className="about-mobile__contact-title about-mobile__contact-title--2">
          business development
        </h3>
        <span className="about-mobile__contact-copy about-mobile__contact-copy--1">
        Direct:
          <a href="mailto:newbusiness@falkoncontent.com">newbusiness@falkoncontent.com</a>
        </span>
        <h3 className="about-mobile__contact-title about-mobile__contact-title--3">
          entertainment
        </h3>
        <span className="about-mobile__contact-copy about-mobile__contact-copy--2">
          Head of Entertainment
          Wondwossen Dikran:
          <a href="mailto:wondwossen@falkoncontent.com">
            wondwossen@falkoncontent.com
          </a>
        </span>
        <h3 className="about-mobile__contact-title about-mobile__contact-title--4">
          publicity
        </h3>
        <p className="about-mobile__contact-copy about-mobile__contact-copy--3">
          <span>Narrative</span>
          Jody Sowa
          Amanda Haynes
        </p>
        <a className="about-mobile__contact-copy about-mobile__contact-copy--4" href="tel16464359810">
          +1 646 435 9810
        </a>
        <a className="about-mobile__contact-copy about-mobile__contact-copy--5" href="mailto:jody.sowa@narrativemediagroup.com">
          jody.sowa@<br />narrativemediagroup.com
        </a>
        <a className="about-mobile__contact-copy about-mobile__contact-copy--6" href="mailto:amanda.haynes@narrativemediagroup.com">
          amanda.haynes@<br />narrativemediagroup.com
        </a>
        <AutoPlayVideo
          poster={img01}
          src={wmnsVideo}
          className="about-mobile__video about-mobile__video--1"
        />
        <Parallax className="about-mobile__img about-mobile__img--1" y={scrollOffset}>
          <ParallaxImage src={img02} />
        </Parallax>
        <Parallax className="about-mobile__img about-mobile__img--2" y={scrollOffset}>
          <ParallaxImage src={img03} />
        </Parallax>
        <AutoPlayVideo
          poster={img04}
          src={protoVideo}
          className="about-mobile__video about-mobile__video--2"
        />
        <img className="about-mobile__img about-mobile__img--3" src={img05} />
        <img className="about-mobile__img about-mobile__img--4" src={img06} />
      </div>
      <SectionTrigger
        bgColor="#afd1d7"
        className="about-mobile__section about-mobile__section--1"
        textColor="#fff"
      />
      <SectionTrigger
        bgColor="#dfb8af"
        className="about-mobile__section about-mobile__section--2"
        textColor="#fff"
      />
      <SectionTrigger
        bgColor="#efede6"
        className="about-mobile__section about-mobile__section--3"
        textColor="#2e63a0"
      />
      <SectionTrigger
        bgColor="#aed1d8"
        className="about-mobile__section about-mobile__section--4"
        textColor="#fff"
      />
    </div>
  )
}

export default AboutContentMobile;