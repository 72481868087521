import React from 'react';
import styled from 'styled-components';
import { Parallax } from 'react-scroll-parallax';
// utils
import { above } from '../../utils';

export const Section1Title = styled(Parallax)`
  grid-area: 1 / 5 / auto / 13;
  z-index: 3;
`;

export const Section1Video1 = styled(Parallax)`
  grid-area: 2 / 1 / auto / 8;
  z-index: 2;
`;

export const Section1Blurb1 = styled(Parallax)`
  grid-area: 2 / 7 / auto / 13;
  z-index: 3;
  p {
    font-size: 1.875vw;

    ${above.huge`
      font-size: 30px;
    `}
  }
`;
export const Section1Img1 = styled(Parallax)`
  grid-area: 3 / 3 / auto / 13;
  z-index: 1;
  transform: translateX(23%);
`;

export const Section1Blurb2 = styled(Parallax)`
  grid-area: 4 / 2 / auto / 8;
  z-index: 3;
  p {
    font-size: 1.875vw;

    ${above.huge`
      font-size: 30px;
    `}
  }
`;

export const Section2Quote1 = styled(Parallax)`
  grid-area: 1 / 1 / auto / 13;
  z-index: 1;
  padding: 20% 0;
`;
export const Section2Img1 = styled(Parallax)`
  grid-area: 2 / 1 / auto / 9;
  z-index: 2;
`;
export const Section2Video1 = styled(Parallax)`
  grid-area: 3 / 1 / auto / 7;
  z-index: 1;
  transform: translateX(-39%);
`;
export const Section2Blurb1 = styled(Parallax)`
  grid-area: 3 / 7 / auto / 13;
  z-index: 3;
  p {
    font-size: 1.875vw;

    ${above.huge`
      font-size: 30px;
    `}
  }
`;
export const Section2Img2 = styled(Parallax)`
  grid-area: 3 / 7 / auto / 13;
  z-index: 2;
  align-self: self-end;
`;
export const Section2Blurb2 = styled(Parallax)`
  grid-area: 4 / 1 / auto / 7;
  z-index: 3;
  margin: 20% 0;
  p {
    font-size: 1.875vw;

    ${above.huge`
      font-size: 30px;
    `}
  }
`;