import React from 'react';
import Fade from 'react-reveal/Fade';
// components
import SectionTrigger from '../SectionTrigger';

function AboutSkillsMobile({ bgColor, textColor }) {
  return (
    <SectionTrigger bgColor={bgColor} textColor={textColor}>
      <div className="about-mobile__skills">
        <Fade bottom duration={1240} delay={100}>
          <h3 className="about-mobile__skills-title">
            Strategy
          </h3>
          <h3 className="about-mobile__skills-title">
            Development
          </h3>
          <h3 className="about-mobile__skills-title">
            Content
          </h3>
          <h3 className="about-mobile__skills-title">
            Media
          </h3>
          <h3 className="about-mobile__skills-title">
            Production
          </h3>
        </Fade>
      </div>
    </SectionTrigger>
  )
}

export default AboutSkillsMobile;