import React from 'react';

const ContactList = () => (
    <div className="contact-list">
        <div className="contact-item">
            <h3 className="contact-item__title">COMMERCIAL REPRESENTATION</h3>
            <p className="contact-item__copy">
                Rogue Rep
                <br />
                Dave Campbell
                <br />
                <a href="mailto:dave@roguerep.tv">
                    dave@roguerep.tv
                </a>
                <br />
                <a href="tel:4252694281">
                    (425) 269.4281
                </a>
            </p>
        </div>
        <div className="contact-item">
            <h3 className="contact-item__title">Entertainment</h3>
            <p className="contact-item__copy">
                Head of Entertainment
                <br />
                Wondwossen Dikran
                <br />
                <a className="hide-desktop" href="mailto:wondwossen@falkoncontent.com hide-desktop">
                    wondwossen@falkoncontent.com
                </a>
                <a className='desktop-only' href="mailto:wondwossen@falkoncontent.com">
                    wondwossen@falkoncontent.com
                </a>
            </p>
            
        </div>
        <div className="contact-item">
            <h3 className="contact-item__title">Representation</h3>
            <p className="contact-item__copy">
                CAA
                <br />
                Rosanna Bilow
                <br />
                <a href="mailto:rbilow@caa.com">
                    rbilow@caa.com
                </a>
                <br />
                <br />
                Will Horowitz
                <br />
                <a href="mailto:will.horowitz@caa.com">
                    will.horowitz@caa.com
                </a>
            </p>
        </div>
        <div className="contact-item">
            <h3 className="contact-item__title">PR & Publicity</h3>
            <p className="contact-item__copy">
                FrontPage Firm
                <br />
                Tosha Whitten
            </p>
            <p className="contact-item__copy">
                <a href="mailto:tosha@frontpagefirm.com" className="hide-desktop">
                    tosha@frontpagefirm.com
                </a>
                <a href="mailto:tosha@frontpagefirm.com" className="desktop-only">
                    tosha@frontpagefirm.com
                </a>
            </p>

            <h3 className="contact-item__title">
                Business <br />
                Development
            </h3>
            <p className="contact-item__copy">
                <br />
                <a href="mailto:newbusiness@falkoncontent.com">
                    newbusiness@falkoncontent.com
                </a>
            </p>
        </div>
    </div>
);

export default ContactList;