import React from 'react';
import PropTypes from 'prop-types';
// components
import SectionTrigger from './SectionTrigger';

const ContactPhone = ({ bgColor, textColor, children }) => (
  <SectionTrigger bgColor={bgColor} textColor={textColor} threshold={0.825}>
    <div className="contact contact--phone">
      <a
        id="contactPhone"
        className="contact-title contact-title--phone"
        href="tel:3105849899"
      >
        310.584.9899
      </a>
      <footer className="fix contact-footer contact-footer--phone">
        <div className="contact-footer__inner">{children}</div>
      </footer>
    </div>
  </SectionTrigger>
);

ContactPhone.propTypes = {
  bgColor: PropTypes.string,
  children: PropTypes.object,
  textColor: PropTypes.string,
};

export default ContactPhone;
