import React from 'react';
import { ParallaxContext, useController } from 'react-scroll-parallax';
// styles
import '../scss/about.scss';
import '../scss/contact.scss';
// hooks
import aboutMedia from 'src/hooks/aboutMedia'
// import aboutMobileMedia from 'src/hooks/aboutMobileMedia'
import useMediaQuery from '../hooks/useMediaQuery';
// lib
import handlePageTransition from '../lib/handle-page-transition';
// components
import SEO from '../components/SEO';
import AboutContent from 'src/components/about/AboutContent';
import AboutContentMobile from 'src/components/about/AboutContentMobile';

function AboutPage({ transitionStatus }) {
  let isDesktop = useMediaQuery('(min-width: 1024px)');
  const isServer = typeof window === 'undefined';
  const context = React.useContext(ParallaxContext)

  if (!isServer && context ) {
    const { parallaxController } = useController();
    handlePageTransition(parallaxController, transitionStatus);
  }

  const images = [];
  const media = aboutMedia();

  media.allCloudinaryMedia.edges.forEach(item => images.push(item.node.secure_url));

  return (
    <div>
      <SEO title="About | Falkon Content" />
      <div className="about">
      {isDesktop
          ? (<AboutContent images={images} />)
          : (<AboutContentMobile images={images} />)
        }
      </div>
    </div>
  );
}

export default React.memo(AboutPage);
