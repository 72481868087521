import React from 'react';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';
// components
import SectionTrigger from './SectionTrigger';
// utils
import { above, size } from '../utils';

const Wrapper = styled.section`
  padding: 15vw 0;

  ${above.huge`
    padding: ${(15 / 100) * size.large}px 0;
  `}

  .skill-block {
    padding: 7.5vw 0;
    position: relative;

    ${above.huge`
      padding: ${(7.5 / 100) * size.large}px 0;
    `}

    &::after {
      display: block;
      content: '';
      height: 8px;
      width: 10vw;
      background: #d7b06f;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 50%);
      position: absolute;

      ${above.huge`
        width: ${(10 / 100) * size.large}px;
      `}
    }

    &:first-of-type {
      padding-top: 0;
    }

    &:last-of-type {
      padding-bottom: 0;

      &::after {
        display: none;
      }
    }
  }

  h3 {
    padding: 0;
    margin: 0;
    text-align: center;
    animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  }

  ul {
    list-style: none;
    margin: 1vw 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);

    ${above.huge`
      margin: ${(1 / 100) * size.large}px 0;
    `}

    li {
      display: inline-block;
      margin: 0 0.8em 0;
      font-weight: 700;
      text-transform: uppercase;
      font-size: ${(30 / 1600) * 100}vw;
      line-height: 1.5;
      letter-spacing: 0.035em;

      ${above.huge`
        font-size: ${(30 / 1600) * size.large}px;
      `}
    }
  }
`;

// eslint-disable-next-line react/prop-types
function AboutSkills({ bgColor, textColor }) {
  return (
    <SectionTrigger bgColor={bgColor} textColor={textColor}>
      <Wrapper>
        <div className="grid__container">
          <Fade bottom duration={1240} delay={100}>
            <div className="skill-block">
              <h3 className="main-heading">Strategy</h3>
            </div>
            <div className="skill-block">
              <h3 className="main-heading">Development</h3>
              <ul>
                <li>Campaigns</li>
                <li>Feature Films</li>
                <li>Series</li>
                <li>Episodic &amp; Content Ideas</li>
              </ul>
            </div>
            <div className="skill-block">
              <h3 className="main-heading">Content Strategy</h3>
            </div>
            <div className="skill-block">
              <h3 className="main-heading">Production</h3>
              <ul>
                <li>Live Action</li>
                <li>Content</li>
                <li>Design</li>
                <li>Animation</li>
                <li>Post-Production</li>
                <li>Finishing</li>
              </ul>
            </div>
            <div className="skill-block">
              <h3 className="main-heading">Media Planning &amp; Strategy</h3>
            </div>
          </Fade>
        </div>
      </Wrapper>
    </SectionTrigger>
  );
}

export default AboutSkills;
